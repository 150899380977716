import { TableResponsive } from 'components/Common/TableResponsive'
import { kyc } from 'helpers/api_url'
import { formatDate } from 'helpers/formatDate'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap'
import { changePreloader } from 'store/actions'

export const Companylist = ({tabNumber}) => {
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const [tabledata,setTableData] = useState([]);
    useEffect(()=>{
        if(tabNumber==2){
            dispatch(changePreloader({status:true,message:'please wait'}));
            kyc.Companylist().then(res=>setTableData(res.data.company))
            .catch(err=>toast.error(err.response?err.response.data.message:err.message))
            .finally(()=>dispatch(changePreloader({status:false,message:''})));
        }
    },[tabNumber])
    const handleCompanyProfile = id => { navigate('/company-profile/'+id); console.log(id)};
    
    const columns = useMemo(() => [
        { Header: "Type", accessor: "business_type", HeaderClass: 'text-center', DataClass: 'text-center' },
        { Header: "Company Name", accessor: "organization_name", HeaderClass: 'text-center', DataClass: 'text-center' },
        { Header: "Brand Name", accessor: "brand_name", HeaderClass: 'text-center', DataClass: 'text-center' },
        { Header: "Email", accessor: "email", HeaderClass: 'text-center', DataClass: 'text-center' },
        {
            Header: 'Action',
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: cell => {
              const row = cell.row.original;
              return (
                <div>
                  <button onClick={() => handleCompanyProfile(row.id)} className="btn btn-sm btn-info"><i className="mdi mdi-eye-outline px-2"></i>View</button>
                </div>
              )
            }
          },
          {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list:(row)=>{
              return (
                <>
                <div className="d-flex">
                  <div className="flex-grow-1" data-id="1">
                    <h5 className="fs-13 mb-1">
                      <a href="#" className="link text-dark"></a>
                      <a href="#">{row.business_type} | <b>{row.organization_name}</b></a>| <span>{row.brand_name}</span>
                    </h5>
                    <p className="text-muted mb-0 no-wrap">
                      {row.email}
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    <div>
                    <button onClick={() => handleCompanyProfile(row.id)} className="btn btn-sm btn-info"><i className="mdi mdi-eye-outline px-2"></i>View</button>
                    </div>
                  </div>
                </div>
                </>
              )
            }
          }

    ]);
    return (
                <Card>
                    <CardHeader className="d-flex align-items-center justify-content-between">
                        <h5 className="card-title mb-0">Company List </h5>
                    </CardHeader>
                    <CardBody>
                        <TableResponsive columns={columns} data={tabledata} noRecord="No Records in List" />
                    </CardBody>
                </Card>
    )
}
