import BreadCrumb from 'components/Common/Breadcrumb'
import Locked from 'components/Common/Locked';
import ProfileLock from 'components/Common/ProfileLock';
import { AssetUrl, kyc } from 'helpers/api_url';
import { formatDate } from 'helpers/formatDate';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import StarRatings from 'react-star-ratings';
import { toast } from 'react-toastify';
import { Form, Badge, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Dropdown, Input, Label, Row, UncontrolledTooltip } from 'reactstrap'
import { changePreloader } from 'store/actions';
export const CompanyPro = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const userData = useSelector(state => state.Login.authUser);
    const [comProfileData, setComProfileData] = useState([]);
    useEffect(() => {
        dispatch(changePreloader({ status: true, message: '' }))
        kyc.companyread(id).then(res => setComProfileData(res.data.company))
            .catch(e => toast.error(e.response ? e.response.data.message : e.message))
            .finally(() => { dispatch(changePreloader({ status: false, message: '' })); });
    }, [id])
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(changePreloader({ status: true, message: 'please wait' }));
        const formData = new FormData(e.target);
        kyc.company(formData)
            .then((res) => { toast.success(res.message, { autoClose: 2000 }); e.target.reset(); })
            .catch((err) => { toast.error(err.response ? err.response.data.message : err.message); })
            .finally(() => dispatch(changePreloader({ status: false, message: '' })));
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Company Profile" prevPage="Dashboard" prevPath="/dashboard" />
                    {
                        comProfileData ? (
                            <>
                                <Card>
                                    <CardBody className='border-bottom'>

                                        <Row>
                                            <Col lg={4} xs={6}>
                                                {/* <div className="text-center mb-4 me-3">
                                                    <img src={AssetUrl + authUser?.other_details?.profile_image} className="avatar-lg rounded-circle" alt={authUser?.first_name} />
                                                </div> */}
                                                <div className="overflow-hidden position-relative">
                                                    <div className="" style={{ backgroundImage: `url(${AssetUrl + comProfileData?.logo})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: 'no-repeat' }}>
                                                        <Row>
                                                            <Col xs="7">
                                                                <div className="text-light p-3">
                                                                    <h5 className="text-light">Welcome Back !</h5>
                                                                    <p>{comProfileData?.organization_name} (FW)</p>
                                                                </div>
                                                            </Col>
                                                            {/* <Col xs="5" className="d-flex justify-content-end my-2">
                                                            <img src={userData?.other_details?.logo === "" ? profileImg : AssetUrl + userData?.other_details?.logo} alt="" className="img-fluid me-2"
                                                                style={{
                                                                    aspectRatio: 1 / 1, height: "124px", objectFit: 'contain',
                                                                    background: 'rgba(0, 0, 0, 0.3)',
                                                                    backdropFilter: 'blur(6px)',
                                                                    padding: '4px',
                                                                    borderRadius: '50%'
                                                                }
                                                                }
                                                            />
                                                        </Col> */}
                                                        </Row>
                                                    </div>
                                                    <CardBody className="pt-0">
                                                        <Row>
                                                            <Col sm="4">
                                                                <div className="avatar-md profile-user-wid mb-4">
                                                                    <img
                                                                        src={comProfileData?.user?.other_details?.profile_image === "" ? avatar1 : AssetUrl + comProfileData?.user?.other_details?.profile_image}
                                                                        alt=""
                                                                        className="img-thumbnail rounded-circle" style={{ aspectRatio: 1 / 1, objectFit: 'cover' }}
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col sm="8" className='mt-3'>
                                                                <h6>Hello, <span>{comProfileData?.user?.first_name} {comProfileData?.user?.last_name}</span>  </h6>
                                                                <p className="text-muted mb-0 text-truncate">{comProfileData?.user?.phone}</p>
                                                                <p className="text-muted mb-0 text-truncate">{comProfileData?.user?.email}</p>

                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </div>
                                            </Col>
                                            <Col lg={4} xs={6}>
                                                <div>
                                                    <div className="d-flex align-items-center">
                                                        <h5>{comProfileData?.brand_name?.toUpperCase()}</h5>
                                                        <div className="text-muted ms-3">
                                                            <StarRatings
                                                                rating={0}
                                                                starRatedColor="#F1B44C"
                                                                starEmptyColor="#74788d"
                                                                numberOfStars={5}
                                                                name="rating"
                                                                starDimension="14px"
                                                                starSpacing="3px"
                                                                className=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <p className="text-muted mb-1">{comProfileData?.business_type?.toUpperCase()} | <Badge className="bg-success px-2">{comProfileData?.representator_designation?.toUpperCase()}</Badge></p>
                                                    <p className="text-default mb-1">{comProfileData?.profession}</p>
                                                    <p className="text-muted mb-3">{formatDate(comProfileData?.registration_date) || '-'} | Years Experience</p>
                                                    <p className="text-muted mb-3">{comProfileData?.mobile} | {comProfileData?.whatsapp}</p>
                                                    <p className="text-muted mb-3">{comProfileData?.email} </p>
                                                    <div className="text-muted  me-3"><span className="pe-3 pt-4">4.5</span><StarRatings rating={4} starRatedColor="#F1B44C" starEmptyColor="#74788d" numberOfStars={5} name="rating" starDimension="14px" starSpacing="3px" className="" /></div>
                                                    <p className="text-muted mb-0">{comProfileData?.about_your_company}</p>
                                                </div>
                                            </Col>
                                            <Col lg={4} xs={12}>
                                                <Form onSubmit={handleSubmit}>
                                                    <Card>
                                                        <CardHeader><CardTitle>Company Legal Document Upload</CardTitle></CardHeader>
                                                        <CardBody>
                                                            <Label htmlFor="type" className='mt-1'>Select</Label>
                                                           <ReactSelect name='type' id='type' options={
                                                            [
                                                                {label:'Registration/CIN', value:'registration_CIN'},
                                                                {label:'Prospectus ', value:'prospectus'},
                                                                {label:'Taxation', value:'taxation'},
                                                                {label:'Pan card', value:'pan_card'},
                                                                {label:'StartUp Docs', value:'startup_docs'},
                                                                {label:'MSME Docs', value:'MSME_docs'},
                                                                {label:'Muncipal/Shop Act', value:'muncipal_shop_act'},
                                                                {label:'ISO Certificate', value:'ISO_certificate'},
                                                                {label:'Importer-Exporter Code', value:'importer_exporter_code'},
                                                                {label:'ITR Acknowledgement , Balance Sheet , Computation, P&L', value:'ITR_acknowledgement_balance_sheet_computation_PandL'},
                                                                
                                                                
                                                                ]
                                                           } />
                                                            <Label htmlFor="heading" className='mt-1'>Document Number</Label>
                                                            <Input type="text" name="heading" id="heading" />
                                                            <Label htmlFor="document" className='mt-1'>Upload</Label>
                                                            <Input type="file" name="document" id="document" />
                                                        </CardBody>
                                                        <CardFooter>
                                                            <Button type="submit" color="primary" className="px-3 my-2"> Submit</Button>
                                                        </CardFooter>
                                                    </Card>

                                                </Form>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardBody>
                                        <Row>
                                            <div className="col-sm-4">
                                                <h5 className="text-info fw-medium mb-2">Address: {comProfileData?.address?.length ? comProfileData?.address[0]?.address : ''}</h5>
                                                <p className="text-muted fw-bold mt-3 mb-1">City : {comProfileData?.address?.length ? comProfileData?.address[0]?.city : ''}</p>
                                                <p className="text-muted fw-bold my-1">District : {comProfileData?.address?.length ? comProfileData?.address[0]?.district : ''}</p>
                                                <p className="text-muted fw-bold my-1">{comProfileData?.address?.length ? comProfileData?.address[0]?.state : ''} - {comProfileData?.address?.length ? comProfileData?.address[0]?.pincode : ''}</p>
                                                <p className="text-muted fw-bold my-1">{comProfileData?.address?.length ? comProfileData?.address[0]?.country : ''}</p>
                                            </div>

                                            <div className="col-sm-4">
                                                <p className="fw-medium mt-0 mb-2 me-2"><h5 className='d-inline'>Register Date : </h5> {formatDate(comProfileData?.registration_date)}</p>
                                                <p className="fw-medium mt-0 mb-2 me-2"><h5 className='d-inline'>Gross Annual Income : </h5> <i className="bx bx-rupee"></i> {comProfileData?.gross_annual_income}</p>
                                                <p className="fw-medium mt-0 mb-2 me-2"><h5 className='d-inline'> Latest Worth Rupees : </h5> <i className="bx bx-rupee"></i> {comProfileData?.latest_worth_rupees}</p>

                                                <div>
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <b className='pt-3 text-center px-5 ' > <Link to={comProfileData?.socials?.length ? comProfileData?.socials[0]?.youtube_link : ''} className='fs-3 text-danger'><i className='bx bxl-youtube'></i></Link> </b>
                                                <b className='pt-3 text-center px-5' > <Link to={comProfileData?.socials?.length ? comProfileData?.socials[0]?.instagram_link : ''} className='fs-3 text-danger'><i className='bx bxl-instagram'></i></Link> </b>
                                                <b className='pt-3 text-center px-5'> <Link to={comProfileData?.socials?.length ? comProfileData?.socials[0]?.facebook_link : ''} className='fs-3 text-primary'><i className='bx bxl-facebook'></i></Link> </b>
                                            </div>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="bg-transparent border-top">
                                        <div className="text-end">
                                            {/* <Button onClick={toggleAppointmentModal} className="btn btn-soft-success me-2 w-md"><i className="bx bxs-bolt"></i> Book Appointment</Button> */}
                                        </div>
                                    </CardFooter>
                                </Card>
                            </>
                        )
                            :
                            (
                                <>
                                    <ProfileLock />
                                </>
                            )
                    }

                </Container>
            </div>
        </React.Fragment>
    )
}
