import {get,post,del,Post} from './api_helper';

export const login = data => post('/login', data);
export const validate = () => get('/validate');
export const logout = () => get('/logout');
export const register = {
	add: (data) => Post('/register', data),
	role: () => get('/role/available'),
};
// export const AssetUrl="http://fittestwarrior.com/fwgames/storage/app/public/";
export const AssetUrl="https://fittestwarrior.com/backend/";
export const kyc={
	add: (data) => Post('/kyc/create', data),
	company: (data) => Post('/company', data),
	list:()=>get('/kyc'),
	Companylist:()=>get('/my-company'),
	companyread:(id)=>get(`/company-profile/${id}`),
}
export const Job={
    add:(data)=>Post('/create-job',data),
    list: () => get('/jobs'),
    delete:(id)=>del(`/delete-job/${id}`),
}
export const Blog_Api = {
	add: (data) =>Post('/create-blog', data),
	list: () => get('/blogs'),
	delete:(data)=>del(`/delete-blog/${data}`),
	updateblog:(data)=>post(`/update-blog`,data),
	fetchSinData:(data)=>get(`/blog/${data}`,data),
};
export const users = {
	participators:()=>get('/users/role/participator'),
	community:()=>get('/users/role/community'),
	brand:()=>get('/users/role/brand'),
	client:()=>get('/users/role/client'),
	supporting_staff:()=>get('/users/role/supporting_staff'),
	proffessional:()=>get('/users/role/Proffessional'),
	frenchisers:()=>get('/users/role/Frenchisers'),
	search:(name)=>get(`/search-user/${name}`),
	profile:()=>get('/profile'),
	updateAddress:(data)=>Post('/user-address',data),
	updateBankAccount:(data)=>Post('/user-bankaccount',data),
	updateEducation:(data)=>Post('/user-education',data),
	updateAbout:(data)=>Post('/update/about',data),
	updateAdditionalDetails:(data)=>Post('/user-additionaldetails',data),
}
export const proffessionals = {
	updateProfile:(data)=>Post('/update-prof-profile',data),
	profile:(id)=>get(`/prof-profile/${id}`),
	myprofile:()=>get(`/prof-profile`),
	updateAvailability:(data)=>post('/update-availability',data),
}

export const timetables={
	addPreset:(preset_id,user_id)=>get(`/store/timetable-preset/${preset_id}/${user_id}`),
	addSchedule:(schedule_id,user_id)=>get(`/store/timetable-schedule/${schedule_id}/${user_id}`),
	addNotes:(data)=>Post(`/store/timetable-notes`,data),
	list:(user_id)=>get(`/read/timetable/${user_id}`),
}

export const community = {
	participators:()=>get('/list-participators'),
	Createparticipator:(data)=>Post('/create-participator',data),
	removeParticipator:(participatorId)=>get(`/remove-participator/${participatorId}`),
	RequestParticipator:()=>get(`/participator-requests`),
	ConfirmParticipator:(participatorId)=>get(`/confirm-participator-request/${participatorId}`),

}

export const AssessmentApi={
	CreateAssessment:(data)=>Post('/assesment/question',data),
	UpdateAssessment:(data)=>Post('assesment/update/question',data),
	allQuestion:()=>get('assesment/question'),
	deleteQuestion:(id)=>get(`assesment/question/${id}`),
}
export const bodyStatic = {
	add: (data) => Post('usrdt/stat',data ),
    update:(data) => Post('usrdt/update/stat', data),
    read: (user_id) => get(`usrdt/stat/${user_id}`),
    del: (id) => del(`usrdt/stat/${id}`)
}
export const Diet = {
	// foods
	add: (data) => Post('/add-food',data ),
	nutrient: (data) => Post('/add-nutrients',data ),
	list: () => get('/food'),
	singleList:(food_id)=>get(`food/${food_id}`),
	// diet plan
	delete:(id)=>del(`food/${id}`),
	update:(data)=>Post('update-food',data),
}
export const food = {
	list:()=>get('/food'),
	add:(data)=>Post('/add-food',data),
	get:(food_id)=>get('/food/'+food_id),
	delete:(id)=>del(`food/${id}`),
}
export const Recipes={
	// recipe
	add_recipe:(data)=>Post('/add-recipe',data),
	list:()=>get('/recepie'),
	get_recipe:(id)=>get(`/recepie/${id}`),
	delete:(id)=>del(`/recepie/${id}`),
}

export const meals = {
	add:(data)=>Post('/diet/meal',data),
	diet:(user_id)=>get(`/diet/${user_id}`),
	list:()=>get('/diet/meal'),
	single_meal:(id)=>get(`/diet/meal/${id}`),
	delete:(id)=>del(`/diet/meal/${id}`),
	meals_on_day:(number,user_id)=>get(`/diet/day/${number}/${user_id}`)
}

export const preset = {
	add:(data)=>Post('/preset',data),
	list:()=>get('/presets'),
	get:(id)=>get('/presets/'+id),
	update:(data)=>Post('/preset-update',data),
	delete:(id)=>del('/preset/'+id),

	addDay:(data)=>Post('/preset-day',data),
	deleteDay:(id)=>del('/preset-day/'+id),
}

export const dashboard={
	profile:(data)=>Post('/update-dashboard-images',data),
}

export const exercise = {
	addCategory:(data)=>Post('/add-category',data),
	getAllCategory:()=>get('/get-category'),
	getCategory:(id)=>get('/get-category/'+id),
	deleteCategory:(cat_id)=>del(`/remove-category/${cat_id}`),
	addExercise:(data) => Post('add-exercise',data),
	get:(id)=>get(`/get-exercise/${id}`),
	list:()=>get('/get-exercise'),
	delete:(id)=>del(`del-exercise/${id}`),
	removeSubCategory:(cat_id,subcat)=>del(`remove-subcategory/${cat_id}/${subcat}`),
	UpdateCategory:(data)=>Post(`/update-category`,data)
}

export const schedule = {
	add:(data)=>Post('/add-schedule',data),
	list:()=>get('/get-schedule'),
	get:(id)=>get(`/get-schedule/${id}`),
	delete:(id)=>del(`/del-schedule/${id}`),
	addDay:(data)=>Post('schedule-day',data),
	deleteDay:(day_id)=>del(`/schedule-day/${day_id}`),
	addDayExercise:(day_id,exercise_id)=>get(`schedule-day/add-exercise/${day_id}/${exercise_id}`),
	removeDayExercise:(day_id,exercise_id)=>del(`schedule-day/remove-exercise/${day_id}/${exercise_id}`),
}

export const appointments = {
	add:(data)=>Post('/appointment',data),
	list:()=>get('/get-appointment'),
	select:(id)=>get('/get-appointment'+id),
	my_appointments:()=>get('/get-my-appointment'),
	select_my_appointments:(id)=>get('/get-my-appointment/'+id),
	approve:(data)=>Post('/approve-appointment',data),
	reject:(id)=>get(`/reject-appointment/${id}`)
}