import BreadCrumb from 'components/Common/Breadcrumb';
import { kyc } from 'helpers/api_url';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Container,  Nav, NavItem, NavLink, Row, TabContent, TabPane, Progress, Form } from 'reactstrap';
import { CompanyDetails } from './CompanyDetails';
import { CompanyAddress } from './CompanyAddress';
import { CompanyRegstration } from './CompanyRegstration';
import { CompanyProfile } from './CompanyProfile';
import { changePreloader } from 'store/actions';
import { CompanySocialMedia } from './CompanySocialMedia';


export const CreateCompany = () => {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(1);
    const totalSteps = 5;
    const handleNextStep = () => { if (activeStep < totalSteps) setActiveStep(activeStep + 1); };
    const handlePreviousStep = () => { if (activeStep > 1) setActiveStep(activeStep - 1); };
    const tabHeaderName = ['Company Details', 'Registration','Address','Social Media', 'Profile Upload'];
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(changePreloader({ status: true, message: 'please wait' }));
        const formData = new FormData(e.target);
        kyc.company(formData)
            .then((res) => {toast.success(res.message, { autoClose: 2000 }); e.target.reset();})
            .catch((err) => {toast.error(err.response ? err.response.data.message : err.message);})
            .finally(() =>dispatch(changePreloader({ status: false, message: '' })));
    };
    return (
        <React.Fragment>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="d-flex align-items-center justify-content-between">
                                    <h5 className="card-title mb-0">Create Company</h5>
                                    {/* <div>
                                        <Link to="#" className="btn btn-soft-dark add-btn waves-effect me-2"> <i className="ri-contacts-fill me-2"></i>Back</Link>
                                    </div> */}
                                </CardHeader>
                                <CardBody>
                                    <Row className="g-3 mx-lg-5">
                                        <div id="custom-progress-bar" className="progress-nav mb-4 mx-0">
                                            <Progress value={(activeStep / totalSteps) * 100} style={{ height: "5px" }} color="primary" />
                                            <Nav className="nav-pills justify-content-center mt-2">
                                                {Array.from({ length: totalSteps }, (_, i) => (
                                                    <NavItem key={i + 1} className='mx-auto'>
                                                        <NavLink className={`rounded-pill d-flex justify-content-center align-items-center  ${activeStep === i + 1 ? 'active' : ''}`} onClick={() => setActiveStep(i + 1)}>  {i + 1}. {tabHeaderName[i]} </NavLink>
                                                    </NavItem>
                                                ))}
                                            </Nav>
                                        </div>
                                        <Form onSubmit={handleSubmit}>
                                            <TabContent activeTab={activeStep}>
                                                <TabPane tabId={1}>
                                                    <CompanyDetails />
                                                    <div className="d-flex justify-content-between">
                                                        <Button onClick={handlePreviousStep} color="secondary" className="px-3 my-2"> Prev</Button>
                                                        <Button onClick={handleNextStep} color="primary" className="px-3 my-2">Next </Button>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={2}>
                                                   <CompanyRegstration />
                                                    <div className="d-flex justify-content-between">
                                                        <Button onClick={handlePreviousStep} color="secondary" className="px-3 my-2"> Prev</Button>
                                                        <Button onClick={handleNextStep} color="primary" className="px-3 my-2">Next </Button>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={3}>
                                                   <CompanyAddress />
                                                    <div className="d-flex justify-content-between">
                                                        <Button onClick={handlePreviousStep} color="secondary" className="px-3 my-2"> Prev</Button>
                                                        <Button onClick={handleNextStep} color="primary" className="px-3 my-2">Next </Button>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={4}>
                                                   <CompanySocialMedia />
                                                    <div className="d-flex justify-content-between">
                                                        <Button onClick={handlePreviousStep} color="secondary" className="px-3 my-2"> Prev</Button>
                                                        <Button onClick={handleNextStep} color="primary" className="px-3 my-2">Next </Button>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={5}>
                                                   <CompanyProfile />
                                                    <div className="d-flex justify-content-between">
                                                        <Button onClick={handlePreviousStep} color="secondary" className="px-3 my-2"> Prev</Button>
                                                        <Button type='submit' color="success" className="px-3 my-2">Submit </Button>
                                                    </div>
                                                </TabPane>
                                               
                                                
                                            </TabContent>

                                        </Form>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
               
        </React.Fragment>
    );
};
